/* eslint-disable complexity */
import { and, equals, filter, isEmpty, pathOr, propOr } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import getErrorMessage from 'helpers/getErrorMessage'
import localforage from 'helpers/localforage'
import { fetchInvoiceItems } from 'redux/modules/invoiceDetails'
import { showModal } from 'redux/modules/modal'
import moment from 'utils/moment'

export {
  countSelector,
  filtersSelector,
  invoicesItemsSelector,
  invoicesListTypeSelector,
  loadingState,
  notFoundState,
  sortSelector,
  sortPrepareSelector,
  isLoadingSelector,
  defaultTabsSelector,
  isFeedbackSendSelector
} from './selector'

export const fetch = createAction('invoices/FETCH')
const fetchSuccess = createAction('invoices/FETCH_SUCCESS')
const fetchFailure = createAction('invoices/FETCH_FAILURE')

export const fetchAggregation = createAction('invoices/FETCH_AGGREGATION')
const fetchAggregationSuccess = createAction('invoices/FETCH_AGGREGATION_SUCCESS')
const fetchAggregationFailure = createAction('invoices/FETCH_AGGREGATION_FAILURE')

export const fetchAction = createAction('invoices/FETCH_ACTION')
const fetchActionSuccess = createAction('invoices/FETCH_ACTION_SUCCESS')
const fetchActionFailure = createAction('invoices/FETCH_ACTION_FAILURE')

export const clearBills = createAction('invoices/CLEAR_BILLS')

export const initFeedback = createAction('invoices/INIT_FEEDBACK')
export const setFeedback = createAction('invoices/SET_FEEDBACK')
const setFeedbackSuccess = createAction('invoices/SET_FEEDBACK_SUCCESS')
const setFeedbackFailure = createAction('invoices/SET_FEEDBACK_FAILURE')

const defaultTabs = [
  {
    id: 'searchBill',
    listType: 'bill',
    title: 'Поиск по счету'
  },
  {
    id: 'in_work',
    listType: 'bill',
    title: 'В обработке'
  },
  {
    id: 'selection',
    listType: 'bill',
    title: 'В сборке'
  },
  {
    id: 'on_way',
    listType: 'shipment',
    title: 'В пути'
  },
  {
    id: 'delivered',
    listType: 'shipment',
    title: 'Доставлен'
  },
  {
    id: 'canceled',
    listType: 'bill',
    title: 'Отменен'
  }
]

const initialState = {
  count: 0,
  entities: {},
  error: '',
  filter: {},
  isError: false,
  isLoaded: false,
  isLoading: false,
  items: [],
  notFound: {
    result: '',
    state: false
  },
  params: {},
  defaultTabs
}

const request = ({ clientApi }) => params => {
  const {
    contractor,
    endDate = moment(),
    invoiceType,
    limit,
    listType = 'bill',
    num = '',
    offset,
    startDate = moment().subtract(1, 'month'),
    status,
    store,
    tab,
    type = 'main',
    user,
    sort,
    order,
    completed,
    orderType
  } = params
  const filterParams = filter(i => !!i, {
    contractor: Number(contractor),
    date_from: moment(startDate, 'DD.MM.YYYY').startOf('day').unix(),
    date_to: moment(endDate, 'DD.MM.YYYY').endOf('day').unix(),
    type: invoiceType,
    status_group: tab,
    status,
    store,
    user: Number(user),
    num
  })

  return clientApi.post(`/v3/invoice/${listType}/${type}/list/`, {
    params: {
      contractor_id: clientApi.getContractorId(),
      filter: completed === 'false'
        ? { ...filterParams, status_group: orderType, completed } 
        : filterParams,
      limit,
      offset,
      sort,
      order
    }
  }).then(({ data }) => fetchSuccess({ num, response: data.response, params })).catch(fetchFailure)
}

const handleFetch = (state, params, { clientApi }) => loop(
  {
    ...state,
    isError: false,
    isLoaded: false,
    isLoading: true,
    notFound: {
      state: false
    },
    params
  },
  Effects.promise(request({ clientApi }), params)
)

const handleFetchSuccess = (state, { num, response, params }) => {
  const count = pathOr(0, ['NAV', 'CNT'], response)
  const entities = propOr({}, 'ENTITIES', response)
  const items = propOr([], 'ITEMS', response)
  const nav = propOr({}, 'NAV', response)

  const notFound = {
    state: and(num, isEmpty(items)),
    result: num
  }

  return loop({
    ...state,
    count,
    entities,
    isError: false,
    isLoaded: true,
    isLoading: false,
    items,
    nav,
    notFound
  },
  Effects.call(fetchAggregation, params))
}

const handleFetchFailure = (state, payload) => loop({
  ...state,
  entities: {},
  items: [],
  isLoaded: false,
  isError: true,
  isLoading: false,
  error: getErrorMessage(payload)
}, Effects.call(showModal, 'fetchInvoicesFailure')
)

const handleClearBills = state => ({
  ...state,
  entities: {},
  items: [],
  isLoaded: false,
  isError: false,
  isLoading: false
})

// Агрегация
const requestAggregation = ({ clientApi }) => ({
  contractor,
  endDate = moment(),
  invoiceType,
  listType = 'bill',
  startDate = moment().subtract(1, 'month'),
  status,
  store,
  tab,
  type = 'main',
  user,
  completed,
  orderType
}) => {

  const filterParams = filter(i => !!i, {
    contractor: Number(contractor),
    date_from: moment(startDate, 'DD.MM.YYYY').startOf('day').unix(),
    date_to:  moment(endDate, 'DD.MM.YYYY').endOf('day').unix(),
    type: invoiceType,
    status_group: tab,
    status,
    store,
    user: Number(user)
  })

  return clientApi.post(
    `/v3/invoice/aggregation/${listType}/${type}/`, {
      params: {
        filter: completed === 'false'
          ? { ...filterParams, status_group: orderType, completed } 
          : filterParams,
        contractor_id: clientApi.getContractorId()
      }
    }
  ).then(({data}) => fetchAggregationSuccess({ response: data.response, type: listType, startDate, endDate }))
    .catch(fetchAggregationFailure)
}

const handleFetchAggregation = (state, params, { clientApi }) => {
  const filterState = propOr({}, 'filter')(state)
  const typeState = propOr('', 'type')(filterState)
  const period = propOr([], 'period')(filterState)
  const listType = propOr('', 'listType')(params)
  const startDate = propOr('', 'startDate')(params)
  const endDate = propOr('', 'endDate')(params)
  const dateChanged = !equals(startDate, period[0]) || !equals(endDate, period[1])

  const effects = []
  // запрос только при изменении даты или вкладки
  if (isEmpty(filterState) || !equals(listType, typeState) || dateChanged) {
    effects.push(Effects.promise(requestAggregation({ clientApi }), params))
  }

  return loop(
    {
      ...state,
      filter: {
        ...state.filter,
        isError: false,
        isLoaded: false,
        isLoading: true
      }
    },
    Effects.batch(effects)
  )
}

const handleFetchAggregationSuccess = (state, { response, type, startDate, endDate }) => {
  const aggregation = propOr({}, 'AGGREGATION', response)
  const entities = propOr({}, 'ENTITIES', response)
  const nav = propOr({}, 'NAV', response)
  return {
    ...state,
    filter: {
      ...state.filter,
      isError: false,
      isLoaded: true,
      isLoading: false,
      aggregation,
      entities,
      type,
      nav,
      period: [startDate, endDate]
    }
  }
}

const handleFetchAggregationFailure = (state, payload) => loop({
  ...state,
  filter: {
    ...state.filter,
    isLoaded: false,
    isError: true,
    isLoading: false
  },
  error: getErrorMessage(payload)
}, Effects.call(showModal, 'fetchInvoicesFailure')
)

// Действия над счетами
const requestAction = ({ clientApi }) => ({
  actionType,
  id
}) => {

  let key
  switch (actionType) {
    case 'cancel': key = 'deactivate'
      break
    case 'confirm': key = 'manual'
      break
    case 'delete': key = 'deactivate'
      break
    case 'restore': key = 'manual'
      break
    default:
      break
  }

  return clientApi.post(`/v3/invoice/bill/${id}/${actionType}/`, {
    params: {
      contractor_id: clientApi.getContractorId(),
      [key]: false
    }
  }).then(({data}) => fetchActionSuccess({ response: data.response })).catch(fetchActionFailure)
}

const handleFetchAction = (state, params, { clientApi }) => {
  return loop(
    {
      ...state,
      isError: false,
      isLoaded: false,
      isLoading: true
    },
    Effects.promise(requestAction({ clientApi }), params)
  )
}

const handleFetchActionSuccess = (state, { response }) => {
  const success = propOr({}, 'SUCCESS', response)
  const id = pathOr('', ['ITEM', 'ID'], response)
  const {params} = state

  return loop({
    ...state,
    isError: false,
    isLoaded: true,
    isLoading: false,
    success
  }, Effects.batch([
    Effects.call(fetch, params),
    Effects.call(fetchInvoiceItems, { id })
  ]))
}

const handleFetchActionFailure = (state, payload) => loop({
  ...state,
  isLoaded: false,
  isError: true,
  isLoading: false,
  success: false,
  error: getErrorMessage(payload)
}, Effects.call(showModal, 'fetchInvoicesFailure')
)

const getItemLocalStorage = ({ key }) =>
  localforage
    .getItem(key)
    .then(value => setFeedbackSuccess({ key, value: !!value }))
    .catch(setFeedbackFailure)

const setItemLocalStorage = ({ key, value }) =>
  localforage
    .setItem(key, value)
    .then(() => setFeedbackSuccess({ key, value }))
    .catch(setFeedbackFailure)

const handleInitFeedback = (state, key = '') => {
  return loop(
    {
      ...state
    },
    Effects.promise(getItemLocalStorage, { key })
  )
}

const handleSetFeedback = (state, { key, value }) =>
  loop(
    {
      ...state
    },
    Effects.promise(setItemLocalStorage, { key, value })
  )

const handleSetFeedbackSuccess = (state, { key, value }) => ({
  ...state,
  feedback: {
    ...state.feedback,
    [key]: value
  }
})

const handleSetFeedbackFailure = state => ({
  ...state
})

export default createReducer(on => {
  on(fetch, handleFetch)
  on(fetchSuccess, handleFetchSuccess)
  on(fetchFailure, handleFetchFailure)
  on(fetchAggregation, handleFetchAggregation)
  on(fetchAggregationSuccess, handleFetchAggregationSuccess)
  on(fetchAggregationFailure, handleFetchAggregationFailure)
  on(fetchAction, handleFetchAction)
  on(fetchActionSuccess, handleFetchActionSuccess)
  on(fetchActionFailure, handleFetchActionFailure)
  on(clearBills, handleClearBills)
  on(initFeedback, handleInitFeedback)
  on(setFeedback, handleSetFeedback)
  on(setFeedbackSuccess, handleSetFeedbackSuccess)
  on(setFeedbackFailure, handleSetFeedbackFailure)
}, initialState)
